<template>
  <div class="map-marker-popup" :style="{ 'border-left': `5px solid ${order.color}` }">
    <div
      class="pt-3
    pb-2 px-2"
    >
      <p class="text-dark m-0"><b>Nome do cliente: </b>{{ order.customer.name }}</p>
      <p class="text-dark m-0"><b>Operação: </b>{{ order.operation.name }}</p>
      <!-- <p class="text-dark m-0">
        <b>Endereço: </b>
        {{ order.operation.name }}
      </p> -->
      <p class="w-100 mt-1">
        <b>Status: </b
        ><span
          class="status-badge rounded my-2 px-3 py-1 font-weight-bold text-center"
          :style="badgeStyle"
        >
          {{ order.statusText }}</span
        >
      </p>

      <b-button
        :href="order.tracking"
        class="w-100 mb-2 tracking-link font-weight-bold"
        size="sm"
        variant="primary "
        target="_blank"
        rel="noopener noreferrer"
        ><icon icon="truckDelivery" :size="20" left /> Link de Rastreio</b-button
      >
    </div>
  </div>
</template>

<script>
// import { readableColor } from 'polished';

export default {
  name: 'map-marker-popup',
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  computed: {
    badgeStyle() {
      return {
        background: this.order.color,
        color: '#fff',
      };
    },
  },
};
</script>

<style lang="scss">
.map-marker-popup {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;

  .status-badge,
  .tracking-link {
    font-size: 12px;
  }
}
</style>
